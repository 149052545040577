

function updateViewportDimensions() {
	var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
	return { width:x,height:y };
}
var viewport = updateViewportDimensions();


var waitForFinalEvent = (function () {
	var timers = {};
	return function (callback, ms, uniqueId) {
		if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
		if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();

var timeToWaitForLast = 100;

function loadGravatars() {
  viewport = updateViewportDimensions();
  if (viewport.width >= 768) {
  jQuery('.comment img[data-gravatar]').each(function(){
    jQuery(this).attr('src',jQuery(this).attr('data-gravatar'));
  });
	}
} 

jQuery(document).ready(function($) {
  loadGravatars();
}); 





document.addEventListener("DOMContentLoaded", function(){




	jQuery(document).ready(function() {
		function scroll_to_top(div) {
			jQuery(div).click(function() {
				jQuery('html,body').animate({scrollTop: 0}, 'fast');
			});
			jQuery(window).scroll(function(){
				if(jQuery(window).scrollTop() < 200){
					jQuery(div).fadeOut();
				} else{
					jQuery(div).fadeIn();
				}
			});
		}
		scroll_to_top("#scroll_to_top");
	  });


	jQuery(document).ready(function(){
		var moving__background = jQuery("#header__background");
		jQuery(window).scroll(function() { 
			moving__background.css('margin-top', (jQuery(window).scrollTop())/3); // Parallax scrolling
			moving__background.css('opacity', 1 - (jQuery(window).scrollTop())/moving__background.height()); // Fading out
		});
	});

	
	window.addEventListener('scroll', function() {
		if (window.scrollY > 100) {
			document.getElementById('navbar_top').classList.add('fixed-top');
			navbar_height = document.querySelector('.navbar').offsetHeight;
			document.body.style.paddingTop = navbar_height + 'px';
		} else {
			 document.getElementById('navbar_top').classList.remove('fixed-top');
			document.body.style.paddingTop = '0';
		}
	});

	jQuery('.nav-link').click(function(){
		jQuery('.navbar div').removeClass('show');
	});


	jQuery('#mycart').click(function(){
		jQuery.ajax({
			url : adminAjax,
			method : 'GET', // On envoie en post
			data : {
				action : 'get_mycart', // On va chercher la fonction
			},
			success : function( data ) { 
				if ( data.success ) {
	
					// On récupere une a une les variables
					var demo1 = data.data.demo1; 
					//var demo2 = data.data.demo2;
	
					console.log(demo1);
					jQuery("#showing").html(""); // on vide avant la balise html
					jQuery("#showing").append(demo1);
	
	
				} else {
	
					// On récupere une a une les variables d'erreurs
					var erreur = data.data; 
	
					console.log( erreur );
	
					// On affiche le contenu en html avec sa valeur
					jQuery("#showing").html(""); // on vide avant la balise html
					jQuery("#showing").append(erreur).addClass('error_msg');
	
				
				}
			},
			error : function( data ) {
				console.log( 'Erreur…' );
			}
		});
	});
	

	jQuery('.owl-one').owlCarousel({
		loop:true,
		margin:0,
		lazyLoad : true,
		nav:false,
		dots:false,
		autoplay:true,
		responsive:{
			0:{
				items:1
			},
			600:{
				items:3
			},
			1000:{
				items:1
			}
		}
	});

	jQuery('#owl_two').owlCarousel({
        loop:true,
        margin:0,
        nav:false,
		lazyLoad : true,
		dots:false,
        responsive:{
            0:{
                items:2
            },
            600:{
                items:2
            },
            1000:{
                items:4
            }
        }
    });

	jQuery('.owl-tree').owlCarousel({
        loop:true,
        margin:20,
        nav:false,
		dots:false,
		lazyLoad : true,
        responsive:{
            0:{
                items:6
            },
            600:{
                items:6
            },
            1000:{
                items:4
            }
        }
    });

    jQuery('#carousel_brands').owlCarousel({
        loop:true,
        margin:0,
        nav:false,
        dots:false,
        autoplay:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:5
            }
        }
    })


	jQuery(document).ready(function () {
		jQuery(".product_type_variable_bulk ").addClass("ajax_add_to_cart");
	});


	/*jQuery(document).ready(function () {
		jQuery(".woocommerce-product-gallery__image img")
		.each(function () {
			jQuery(this).replaceWith('<a data-fancybox="gallery" href="' + jQuery(this).attr("data-src") + '">' + jQuery(this)[0].outerHTML + "</a>");
		})
		.promise()
		.done(function () {});
	});
	*/



});
